<template>
  <div class="infoedit">
    <h1>회원정보 수정</h1>
    <p class="myfix"><span class="">{{userData.memNick}}</span>{{$t('front.mypage.infoEdit')}}{{$t('front.mypage.security')}}</p>
    <div class="myfixwrap">
      <ul class="joinu">
        <li>{{$t('front.common.memId')}}</li>
        <li class="border">{{userData.memId}}</li>
      </ul>
      <ul class="joinu">
        <li>{{$t('front.common.password')}}</li>
        <li><input type="password" placeholder="" v-model="model.memPass"></li>
      </ul>
      <span class="joinutext">{{$t('front.signup.passwordPlaceholder')}}</span>
      <ul class="joinu">
        <li>{{$t('front.mypage.passwordOk')}}</li>
        <li><input type="password" placeholder="" v-model="model.memPassCheck"></li>
      </ul>
      <ul class="joinu">
        <li>{{$t('front.common.cashOutPass')}}</li>
        <li><input type="text" id="cashOutPass"  maxlength="4"  pattern="[0-9]*" v-model="model.cashOutPass" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"  inputmode="numeric"  placeholder="" /></li>
      </ul>
      <span class="joinutext">{{$t('front.signup.cashOutPassPlaceholder')}}</span>
      <ul class="joinu">
        <li>{{$t('front.mypage.ExchangepasswordOk')}}</li>
        <li><input  type="text" id="cashOutPassCheck" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"  maxlength="4"  pattern="[0-9]*" inputmode="numeric"  placeholder="" v-model="model.cashOutPassCheck"></li>
      </ul>
      <div class="editBtn"><a class="" @click="onUpdate">{{$t('front.board.edit')}}</a></div>
    </div>
  </div>
</template>

<script>
import { isValidOnlyNumber, isValidPassword } from '@/libs/utils'
import { memberUpdate } from '@/api/member'

export default {
  name: 'MyPageInfo',
  data () {
    return {
      model: {
        memPass: '',
        cashOutPass: '',
        cashOutPassCheck: '',
        memPassCheck: ''
      }
    }
  },
  methods: {
    isValidate () {
      const data = this.model
      for (const key of Object.keys(data)) {
        const value = data[key]

        if (key === 'memPassCheck') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.compareMemPass')
            return false
          }
        }

        if (key === 'memPass') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.emptyMemPass')
            return false
          }

          if (!isValidPassword(value) || value.length > 20) {
            this.onAlert('warningart', 'api.U102')
            return false
          }
        }

        if (key === 'cashOutPassCheck') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.emptyCashOutPass')
            return false
          }
        }

        if (key === 'cashOutPass') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.emptyCashOutPass')
            return false
          }

          if (!isValidOnlyNumber(value) || value.toString().length !== 4) {
            this.onAlert('warningart', 'api.U103')
            return false
          }
        }

        if (data.memPass !== data.memPassCheck) {
          this.onAlert('warningart', 'front.member.compareMemPass')
          return false
        }

        if (data.cashOutPass !== data.cashOutPassCheck) {
          this.onAlert('warningart', 'front.member.compareCashPassCheck')
          return false
        }
      }

      return true
    },
    onUpdate () {
      if (this.isValidate()) {
        const param = {
          memId: this.userData.memId,
          memPass: this.model.memPass,
          cashOutPass: this.model.cashOutPass
        }

        memberUpdate(param).then(response => {
          const result = response.data
          if (result.resultCode === '0') {
            this.onCheck('front.member.completeUpdate')
          } else {
            this.onCheck('api.' + result.resultCode)
          }

          this.model = {
            memPass: '',
            cashOutPass: '',
            cashOutPassCheck: ''
          }
        })
      }
    }
  }
}
</script>
<style scoped>
.infoedit {max-width: 700px;width: 100%;}
#cashOutPass, #cashOutPassCheck {
  -webkit-text-security: disc;
}
.infoedit h1 {color:#0f0f0f; font-size: 21px; font-weight: 600; margin-bottom:10px; margin-top: 28px;}
.myfix {font-size: 14px;line-height: 1.5em;color: #000000; font-weight: 600;}
.myfix span {color: #5068d4;}
.myfixwrap {margin-top: 12px;padding: 25px 20px;border-radius: 5px;display: flex;flex-direction: column;gap: 16px; background: #fff;}
.joinu {display: flex;gap: 10px;flex-wrap: wrap;flex-direction: column;}
.joinu li {width: 100%;position: relative;display: flex;align-items: center;font-size: 14px;color: #121212;height: 37px; box-sizing: border-box;}
.joinu li:first-child {width: 150px;justify-content: flex-start; height: 21px;  border: 0; font-weight: 600;}
.joinu li.border {border: solid 1px #dadde2;border-radius: 5px; padding-left: 12px;}
.joinutext {font-weight: 600;font-size: 12px;color: #939ba2;padding: 0 0 0 12px;text-align: left;display: block;}
.joinu input {padding: 0 0 0 12px;height: 40px;width: 100%;font-size: 14px;color: #121212;border: solid 1px #dadde2;border-radius: 5px;}
.joinubottom {border: solid 1px #ef6621;border-radius: 3px;padding: 18px 0;display: flex;align-items: center;justify-content: center;gap: 110px;color: #121212;font-size: 14px;color :#ef6621;}
.joinubottom > ul {display: flex;gap: 10px;}
.joinubottom > ul li:first-child {color: #bfbfbf;}

.editBtn {display: flex;justify-content: flex-end;align-items: center;}
.editBtn a {color: #fff;font-size: 14px;display: flex;align-items: center;justify-content: center;width: 92px;height: 32px;border-radius: 3px;background: #5068d4;}
@media (max-width: 1000px) {
  .myfixwrap {padding: 15px;gap: 10px;}
  .joinu {gap: 10px;}
  .joinu li {width: calc(100% - 132px);}
  .joinu li:first-child {width: 120px;font-size: 12px;}
  .joinutext {font-size: 12px;padding: 0 0 0 130px;}
}
</style>
<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
